<template>
  <div class="page-wrap">
    <div class="main-page-column">
      <message-box
        height="70%"
        icon="mdi-emoticon-sad-outline"
        title="It seems you lost your way"
        caption="The page you requested could not be found"
        caption-color="primary--text"
      >
        <v-btn color="primary" to="/">
          <v-icon left>mdi-home-outline</v-icon>Return Home
        </v-btn>
      </message-box>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'

export default {
  name: 'PageNotFound',
  components: {
    MessageBox
  }
}
</script>

<style></style>
